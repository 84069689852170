<template>
  <div class="stats" v-if="hasPerm('stats')">
    <page-header title="Statistiques" icon="fas fa-chart-pie"></page-header>
    <b-row>
      <b-col>
        <div>
          <ul class="app-menu">
            <li v-if="hasPerm('youth.view_seanceinscription')">
              <router-link :to="{ name: 'stats-youth-attendance', }">
                <i class="fas fa-chart-bar"></i> Fréquentations {{ youthHomeLabel }}
              </router-link>
            </li>
            <li v-if="hasPerm('youth.view_seanceinscription')">
              <router-link :to="{ name: 'stats-youth-management', }">
                <i class="fas fa-euro-sign"></i> Gestion {{ youthHomeLabel }}
              </router-link>
            </li>
            <li v-if="hasPerm('youth.view_seanceinscription')">
              <router-link :to="{ name: 'stats-youth-welfare', }">
                <i class="fas fa-hands-holding-circle"></i> Aides {{ youthHomeLabel }}
              </router-link>
            </li>
            <li v-if="hasPerm('youth.view_seanceinscription')">
              <router-link :to="{ name: 'stats-youth-fixed-fee', }">
                <i class="fas fa-layer-group"></i> Forfaits {{ youthHomeLabel }}
              </router-link>
            </li>
            <li v-if="hasPerm('youth.view_seanceinscription')">
              <router-link :to="{ name: 'stats-youth-attendance-list', }">
                <i class="fas fa-th-list"></i> Fréquentations {{ youthHomeLabel }} - liste des inscrits
              </router-link>
            </li>
            <li v-if="hasPerm('activities.view_coreactivity')">
              <router-link :to="{ name: 'stats-activity-inscriptions', }">
                <i class="fas fa-chart-bar"></i> Inscrits aux activités et événements
              </router-link>
            </li>
            <li v-if="hasPerm('activities.view_coreactivity')">
              <router-link :to="{ name: 'stats-activity-sales', }">
                <i class="fas fa-money-bill-1-wave"></i> Ventes des activités et événements
              </router-link>
            </li>
            <li v-if="hasPerm('youth.view_youthhomeactivity')">
              <router-link :to="{ name: 'stats-youth-inscriptions', }">
                <i class="fas fa-chart-bar"></i> Inscrits {{ youthHomeLabel }}
              </router-link>
            </li>
            <li v-if="hasPerm('youth.view_youthhomeactivity')">
              <router-link :to="{ name: 'stats-youth-sales', }">
                <i class="fas fa-money-bill-1-wave"></i> Ventes des inscriptions {{ youthHomeLabel }}
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'stats-members-by-age', }">
                <i class="fas fa-chart-bar"></i> Membres par âge
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'stats-members-by-location', }">
                <i class="fas fa-chart-bar"></i> Membres par lieu
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'stats-family-compositions', }">
                <i class="fas fa-people-roof"></i> Composition des familles
              </router-link>
            </li>
          </ul>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/Layout/PageHeader'
import { BackendMixin } from '@/mixins/backend'
import store from '@/store'

export default {
  name: 'stats-home',
  mixins: [BackendMixin],
  components: {
    PageHeader,
  },
  computed: {
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
  },
}
</script>

<style lang="less">
</style>
